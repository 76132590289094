import React from 'react';

const Clock = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="#190D33"
      xmlns="http://www.w3.org/2000/svg">
      <g fillRule="evenodd">
        <path
          d="M8.5 0C3.78636364 0 0 3.78636364 0 8.5 0 13.2136364 3.78636364 17 8.5 17c4.7136364 0 8.5-3.7863636 8.5-8.5C17 3.78636364 13.2136364 0 8.5 0zm0 15.4545455c-3.86363636 0-6.95454545-3.0909091-6.95454545-6.9545455 0-3.86363636 3.09090909-6.95454545 6.95454545-6.95454545 3.8636364 0 6.9545455 3.09090909 6.9545455 6.95454545 0 3.8636364-3.0909091 6.9545455-6.9545455 6.9545455z"
          fillRule="nonzero"
        />
        <path d="M11.6140704 8.48L9.33854458 7.12V2.8c0-.48-.26770892-.8-.66927229-.8S8 2.32 8 2.8v4.8c0 .32.13385446.56.40156337.72l2.67708913 1.6c.0669273.08.1338545.08.267709.08.2677089 0 .4684906-.16.602345-.48.1338545-.32 0-.8-.3346361-1.04z" />
      </g>
    </svg>
  );
};
export default Clock;
