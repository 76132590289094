import React from 'react';

const Price = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="#190D33"
      xmlns="http://www.w3.org/2000/svg" >
      <path
        d="M5.39170507 7.48177083C7.60368664 8.05729167 10 8.8984375 10 11.5989583c0 1.9479167-1.52073733 3.0104167-3.4562212 3.3645834V17H3.59447005v-2.0364583C1.70506912 14.5651042.13824885 13.4140625 0 11.3333333h2.16589862c.0921659 1.1067709.87557603 1.9921875 2.9032258 1.9921875 2.16589862 0 2.67281106-1.0182291 2.67281106-1.6822916 0-.8854167-.50691244-1.72656253-2.94930875-2.30208337C2.02764977 8.72135417.1843318 7.61458333.1843318 5.4453125c0-1.81510417 1.52073732-3.01041667 3.41013825-3.40885417V0H6.5437788v2.08072917c2.07373272.48697916 3.13364055 1.94791666 3.1797235 3.5859375H7.55760369c-.04608295-1.1953125-.73732719-1.9921875-2.48847927-1.9921875-1.65898617 0-2.62672811.75260416-2.62672811 1.77083333 0 .88541667.73732719 1.4609375 2.94930876 2.03645833z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default Price;
