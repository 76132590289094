export const formattingEvents = (dataFromMeetup) => {
  const EventFormSubjects = [];

  dataFromMeetup.forEach((event) => {
    event.venue = event.venue || {};
    EventFormSubjects.push({
      label: `${event.date_long_fr} - ${event.time_24}`,
      value: event._id,
      date: event.date_long_fr,
      type: event.event_type === 'information meeting' ? "Réunion d'information" : event.event_type,
      name: event.name,
      address: `${event.venue.address_1}`,
      city: `${event.venue.city}, ${event.venue.localized_country_name}`,
      time: event.time_24,
      isOnline: event.isOnline,
    });
  });
  return EventFormSubjects;
};

export const formattingSelectedEvent = (selectedEvent) => {
  const result = {};

  selectedEvent.venue = selectedEvent.venue || {};

  result.label = `${selectedEvent.date_long_fr} - ${selectedEvent.time_24}`;
  result.value = selectedEvent._id;
  result.date = selectedEvent.date_long_fr;
  result.type =
    selectedEvent.event_type === 'information meeting'
      ? "Réunion d'information"
      : selectedEvent.event_type;
  result.name = selectedEvent.name;
  result.address = `${selectedEvent.venue.address_1}`;
  result.city = `${selectedEvent.venue.city}, ${selectedEvent.venue.localized_country_name}`;
  result.time = selectedEvent.time_24;
  result.isOnline = selectedEvent.isOnline;

  return result;
};
