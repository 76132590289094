import styled from 'src/style';
import { mediaQueries } from 'src/style/breakpoints';

/**
 * @type import('App').AppStyledComponent<{ small?: boolean }>
 */
export const StyledComp = styled.div({
  minHeight: 29,
  borderRadius: 3,
  padding: '5px 8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(255,255,255,0.42)',
});

export const TagContainerCenter = styled.div({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 10,
});

export const TagContainer = styled.div(
  {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ landing }) => ({
    [mediaQueries.tabletPortraitUp]: {
      justifyContent: landing ? 'initial' : 'center',
    },
    [mediaQueries.desktopUp]: {
      justifyContent: 'initial',
    },
    [mediaQueries.phoneOnly]: {
      flexWrap: landing && 'wrap',
    },
  }),
  ({ wrapped }) => ({
    flexWrap: wrapped && 'wrap',
    gap: wrapped && '10px',
  })
);

export const TagContainerNotCenter = styled.div({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});
