import styled from 'src/style';
import colors from 'src/style/colors';
import MenuOpened from '../Icons/MenuOpened';
import { StyledBtn } from 'src/components/Button/elements';

export const ModalBackground = styled.div(
  {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: colors.purpleDark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 600ms linear 0s',
    transform: 'all',
    zIndex: 2000,
    overflow: 'auto',
  },
  ({ isOpen }) => ({ opacity: isOpen ? 1 : 0, visibility: isOpen ? 'visible' : 'hidden' })
);

export const ModalTransparentBackground = styled.div(
  {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 600ms linear 0s',
    transform: 'all',
    zIndex: 2000,
    overflow: 'auto',
  },
  ({ isOpen }) => ({ opacity: isOpen ? 1 : 0, visibility: isOpen ? 'visible' : 'hidden' })
);

export const Close = styled(MenuOpened)({
  cursor: 'pointer',
  color: colors.white,
  position: 'absolute',
  zIndex: 2,
});

export const VideoContainer = styled.div(({ mqs, landing }) => ({
  width: mqs && mqs.tabletLandscapeUp ? '80vw' : landing ? '100%' : '100vw',

  // height: mqs.desktopUp || mqs.bigDesktopUp ? '80vh' : '50vh',
  height: landing ? null : '80vh',
  aspectRatio: landing ? '16/9' : 'auto',
}));

export const ButtonModal = styled(StyledBtn.withComponent('div'))({
  padding: '16px 35px',
  marginTop: 56,
});
