import React from 'react';
import { StyledComp } from './elements';
import { UppercaseWords } from '../Typography';
import colors from 'src/style/colors';

const Tag = ({ type, hero, home, children, color, spacer }) => {
  let tag;
  switch (type) {
    case 'onSite':
      tag = 'sur place';
      break;
    case 'online':
      tag = 'à distance';
      break;
    case 'e-learning':
      tag = 'e-learning';
      break;
    case 'onSiteAndOrOnline':
      tag = 'sur place et/ou à distance';
      break;
    case 'onSiteAndOnline':
      tag = 'sur place et à distance';
      break;
    case 'onSiteOrOnline':
      tag = 'sur place ou à distance';
      break;

    default:
      tag = children;
      break;
  }
  const style = {
    background: color
      ? colors[color]
      : home
      ? color
        ? colors[color]
        : 'rgba(233,233,240,0.8)'
      : 'rgba(233,233,240,0.8)',
    marginTop: hero && 24,
    marginBottom: home && 10,
    marginRight: spacer && 10,
  };
  const darkColors = ['purpleDark', 'purple'];
  return (
    <StyledComp css={style}>
      <UppercaseWords
        css={{
          paddingBottom: 0,
          letterSpacing: 1,
          fontSize: 12,
          color: darkColors.includes(color) ? '#FFFFFF' : '#190D33',
          whiteSpace: 'noWrap',
        }}
      >
        {tag}
      </UppercaseWords>
    </StyledComp>
  );
};

export default Tag;
