import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
/* --- import components ----*/
import Layout from 'src/components/Layout';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { Section } from 'src/components/Section';
import EventCards from 'src/components/Events/EventCards';
import { HeaderDescription, HeaderTitle } from 'src/components/Typography';
import { CTACourses } from 'src/components/CTA';
import { HeroLeft, HeroWrapper } from 'src/components/Hero/elements';
/* --- import Style ----*/
import { mediaQueries } from 'src/style/breakpoints';
import colors from 'src/style/colors';
/* --- import custom hook ----*/
import { useFutureDataFromMeetup } from 'src/utils/useFutureDataFromMeetup';

const Events = ({ location, pageContext: { nextSessions, dataFromMeetup } }) => {
  const [isLoading, futureDataFromMeetup] = useFutureDataFromMeetup(dataFromMeetup);

  const eventRichDatas = dataFromMeetup.map((item) => {
    let endDate = new Date(`${item.date_en} ${item.time_24}`);
    endDate.setHours(endDate.getHours() + 2);

    return {
      '@context': 'https://schema.org',
      '@type': 'Event',
      name: item.event_type,
      description: item.name,
      startDate: new Date(`${item.date_en} ${item.time_24}`),
      endDate: endDate,
      eventAttendanceMode: 'OnlineEventAttendanceMode',
      eventStatus: 'EventScheduled',
      location: {
        '@type': 'VirtualLocation',
        url: 'https://zoom.us/fr-fr/meetings.html',
      },
      performer: {
        '@type': 'Person',
        name: 'Xavier Colombel',
      },
      organizer: {
        '@type': 'Organization',
        name: 'Le Reacteur',
        url: 'https://www.lereacteur.io/',
      },
      offers: {
        '@type': 'Offer',
        price: '0',
        priceCurrency: 'USD',
        availability: 'https://schema.org/InStock',
        url: 'https://www.lereacteur.io/evenements/',
      },
    };
  });

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C"
      pageName="Evénements"
      pageType="page"
      metaTitle="Inscrivez-vous à l'un de nos évènements | Le Reacteur"
      metaDescription="Vous souhaitez assister à l'une de nos réunions d'information ? Assister aux présentations de projets de nos élèves ? Découvrez nos évènements !"
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          position: 'relative',
          height: 700,
          marginBottom: '-80px',
          [mediaQueries.phoneOnly]: {
            marginBottom: '0px',
            height: 620,
          },
        }}
      >
        <TopbarStaticSpace />
        <Section theme="primary" hero={true} margin>
          <HeroWrapper>
            <HeroLeft css={{ flex: 1 }}>
              <HeaderTitle>Événements et workshops</HeaderTitle>
              <HeaderDescription
                css={{
                  marginTop: 32,
                }}
              >
                Parcourez nos événements et nos workshops à venir. <br />
                Venez assister à nos meetups, réunions d'information, workshops et présentations de
                projets.
              </HeaderDescription>
            </HeroLeft>
          </HeroWrapper>
        </Section>
      </div>

      <Section theme="secondary">
        <div
          css={{
            position: 'relative',
            [mediaQueries.phoneOnly]: {
              marginTop: '-50%',
            },
            marginTop: -230,
          }}
        >
          {isLoading ? (
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <MoonLoader color={colors.white} size={20} />
            </div>
          ) : (
            futureDataFromMeetup.map((item) => {
              return <EventCards key={item._id} event={item} />;
            })
          )}
        </div>
      </Section>

      <CTACourses nextSessions={nextSessions} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(eventRichDatas),
        }}
      />
    </Layout>
  );
};

export default Events;
