import React from 'react';
import { Link } from 'gatsby';

import { StyledLinkNoURL } from '../Typography';
import { Description } from 'src/components/DescriptionBlock/index';
import colors from 'src/style/colors';
import { ArrowColored } from 'src/components/Icons/arrows';
import Location from 'src/components/Icons/location';
import Clock from 'src/components/Icons/Clock';
import Price from 'src/components/Icons/Price';
import CameraBlack from 'src/components/Icons/Visio/CameraBlack';

import { mediaQueries } from 'src/style/breakpoints';
import { useHover } from '../../utils/useHover';
import {
  StyledDate,
  CTAWrapper,
  EvendCardContainer,
  ContactInfoItem,
  ContactInfoWrapper,
  StyledSentence,
  IconWrapper,
} from './elements';
import { useMediaQueries } from '../../utils/MediaQueries';
import { formattingSelectedEvent } from 'src/components/EventForm/formattingEvents';

const EventCards = ({ event }) => {
  const mqs = useMediaQueries();
  const [hoverRef, isHover] = useHover();

  if (event.event_type === 'information meeting') {
    event.event_type = "Réunion d'information";
  }
  const formattedEvent = formattingSelectedEvent(event);

  return (
    <Link
      to="/evenements/inscription"
      state={formattedEvent}
      rel="noopener noreferrer"
      css={{
        textDecoration: 'none',
      }}
    >
      <EvendCardContainer ref={hoverRef} isHover={isHover} eventPage={true}>
        <div css={{ display: 'flex', flexDirection: 'column', flex: 3 }} data-cy="eventCard">
          <StyledDate>{event.date_short_fr}</StyledDate>
          <Description title={event.event_type} subtitle={event.name} />

          {mqs.tabletLandscapeUp && (
            <CTAWrapper css={{ paddingTop: 25 }}>
              <StyledLinkNoURL css={{ fontWeight: 'bold', fontSize: 20, paddingRight: 8 }}>
                Participer
              </StyledLinkNoURL>
              <ArrowColored color={colors.purple} />
            </CTAWrapper>
          )}
        </div>
        <ContactInfoWrapper>
          {event.isOnline !== true ? (
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                [mediaQueries.tabletPortraitUp]: {
                  flex: 2,
                  marginRight: 10,
                },
                [mediaQueries.phoneOnly]: {
                  backgroundColor: 'red',
                },
              }}
            >
              <IconWrapper
                css={{
                  [mediaQueries.phoneOnly]: {
                    alignItems: 'center',
                  },
                }}
              >
                <Location
                  css={{
                    marginTop: 2,
                    [mediaQueries.phoneOnly]: {
                      marginTop: 0,
                    },
                  }}
                />
              </IconWrapper>
              <div
                css={{
                  [mediaQueries.tabletLandscapeUp]: {
                    paddingLeft: 2,
                  },
                }}
              >
                <StyledSentence css={{ fontWeight: 'bold' }}>Le Reacteur</StyledSentence>
                <StyledSentence>
                  55, Rue Etienne Marey
                  <br />
                  75020 - Paris
                </StyledSentence>
              </div>
            </div>
          ) : (
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                [mediaQueries.tabletPortraitUp]: {
                  flex: 2,
                  marginRight: 16,
                },
              }}
            >
              <IconWrapper
                css={{
                  [mediaQueries.tabletLandscapeUp]: {
                    paddingLeft: 2,
                  },
                  [mediaQueries.phoneOnly]: {
                    alignItems: 'center',
                  },
                }}
              >
                <CameraBlack />
              </IconWrapper>

              <StyledSentence>
                Visio-conférence <br />
                (via Zoom)
              </StyledSentence>
            </div>
          )}
          <ContactInfoItem
            css={{
              [mediaQueries.phoneOnly]: {
                alignItems: 'flex-end',
              },
            }}
          >
            <IconWrapper>
              <Clock css={{ marginTop: 2 }} />
            </IconWrapper>
            <StyledSentence>{event.time_24}</StyledSentence>
          </ContactInfoItem>
          <ContactInfoItem
            css={{
              [mediaQueries.phoneOnly]: {
                alignItems: 'flex-end',
              },
            }}
          >
            <IconWrapper>
              <Price
                css={{
                  marginTop: 2,
                  [mediaQueries.phoneOnly]: {
                    paddingLeft: 2,
                  },
                }}
              />
            </IconWrapper>
            <StyledSentence>{event.fee ? event.fee : 'Gratuit'}</StyledSentence>
          </ContactInfoItem>
        </ContactInfoWrapper>
      </EvendCardContainer>
    </Link>
  );
};
export default EventCards;
