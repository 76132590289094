import styled from 'src/style';
import { Sentence } from '../Typography/index';
import { mediaQueries } from 'src/style/breakpoints';
import colors from 'src/style/colors';

export const StyledDate = styled(Sentence)({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 14,
  paddingBottom: 16,
  [mediaQueries.phoneOnly]: {
    fontSize: 12,
  },
});

export const ContactInfoWrapper = styled.div({
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  [mediaQueries.phoneOnly]: {
    paddingTop: 16,
  },
  [mediaQueries.tabletPortraitUp]: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  [mediaQueries.tabletLandscapeUp]: {
    flexDirection: 'column',
    paddingLeft: 64,
  },
});

export const YellowLine = styled.div(
  {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 10,
    WebkitMaskImage: '-webkit-radial-gradient(white, black)',
    backgroundColor: colors.yellow,
    transitionProperty: 'all',
    transitionDuration: '0.2s',
    borderRadius: '10px 0px 0px 10px',
    transitionTimingFunction: 'cubic-bezier(0.41, 0.094, 0.54, 0.07)',
  },
  ({ isHover }) => ({
    transform: isHover ? 'translateX(0px)' : 'translateX(-15px)',
  })
);

export const ContactInfoItem = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  [mediaQueries.phoneOnly]: {
    paddingTop: 16,
  },
  [mediaQueries.tabletLandscapeUp]: {
    paddingTop: 32,
  },
});

export const CTAWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const StyledSentence = styled(Sentence)({
  fontSize: 16,
  lineHeight: '22px',
  [mediaQueries.phoneOnly]: {
    fontSize: 14,
    lineHeight: '18px',
  },
});

export const IconWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  [mediaQueries.phoneOnly]: {
    justifyContent: 'flex-start',
    width: 35,
  },
  [mediaQueries.tabletPortraitUp]: {
    justifyContent: 'flex-start',
    marginRight: 10,
  },
  [mediaQueries.tabletLandscapeUp]: {
    marginRight: 16,
    width: 35,
    justifyContent: 'center',
  },
});

export const EvendCardContainer = styled.div(
  {
    maxWidth: 1005,
    minHeight: 230,
    position: 'relative',
    backgroundColor: colors.white,
    borderRadius: 10,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    boxSizing: 'content-box',
    [mediaQueries.phoneOnly]: {
      padding: 24,
    },
    [mediaQueries.tabletPortraitUp]: {
      padding: '32px 32px',
    },
    [mediaQueries.tabletLandscapeUp]: {
      flexDirection: 'row',
      padding: '32px 64px',
    },
  },
  ({ isHover, eventPage }) => ({
    /* ...(isHover && !eventPage
      ? {
          [mediaQueries.tabletPortraitUp]: {
            paddingLeft: 60,
          },
          [mediaQueries.tabletLandscapeUp]: {
            paddingLeft: 70,
          },
        }
      : {}), */
    boxShadow:
      isHover && eventPage
        ? '0 15px 20px rgba(0,0,0,.11)'
        : eventPage
        ? '0 4px 8px rgba(0,0,0,.04)'
        : isHover && eventPage === false
        ? '0 10px 15px rgba(0,0,0,.09)'
        : '0 5px 15px rgba(0,0,0,.07)',
    transform: isHover && eventPage && 'translateY(-7px)',
    transition: eventPage
      ? 'all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s'
      : 'all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s',
  })
);
