import React from 'react';

const Location = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="14"
      height="17"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M7 0C3.15 0 0 3.1875 0 7.08333333 0 12.325 6.37 16.7166667 6.58 16.8583333 6.72 16.9291667 6.86 17 7 17c.14 0 .28-.0708333.42-.1416667C7.63 16.7166667 14 12.325 14 7.08333333 14 3.1875 10.85 0 7 0zm0 15.4416667C5.67 14.45 1.4 10.9083333 1.4 7.08333333c0-3.11666666 2.52-5.66666666 5.6-5.66666666 3.08 0 5.6 2.55 5.6 5.66666666C12.6 10.9083333 8.33 14.45 7 15.4416667zM7 4.25c-1.54 0-2.8 1.275-2.8 2.83333333 0 1.55833334 1.26 2.83333334 2.8 2.83333334 1.54 0 2.8-1.275 2.8-2.83333334C9.8 5.525 8.54 4.25 7 4.25zM7 8.5c-.77 0-1.4-.6375-1.4-1.41666667 0-.77916666.63-1.41666666 1.4-1.41666666.77 0 1.4.6375 1.4 1.41666666C8.4 7.8625 7.77 8.5 7 8.5z"
          id="a"
        />
      </defs>
      <use fill="#190D33" xlinkHref="#a" />
    </svg>
  );
};
export default Location;
