import { useState, useEffect } from 'react';
import axios from 'axios';

export const useFutureDataFromMeetup = (dataFromMeetup) => {
  const [futureDataFromMeetup, setFutureDataFromMeetup] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCurrentParisTime = async () => {
      try {
        const response = await axios.get('https://worldtimeapi.org/api/timezone/Europe/Paris');
        if (response.status === 200 && response.data.unixtime) {
          const filteredData = dataFromMeetup.filter((e) => {
            return e.time_utc >= response.data.unixtime * 1000;
          });
          setFutureDataFromMeetup(filteredData);
          setIsLoading(false);
        } else {
          setFutureDataFromMeetup(dataFromMeetup);
          setIsLoading(false);
        }
      } catch (error) {
        setFutureDataFromMeetup(dataFromMeetup);
        setIsLoading(false);
        console.error("Est-ce que l'API world time est accessible ?");
        throw error;
      }
    };
    getCurrentParisTime();
  }, [dataFromMeetup]);
  return [isLoading, futureDataFromMeetup];
};
